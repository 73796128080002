<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item label="场地id, res_site.id" prop="siteId" >
        <a-input v-model="form.siteId" placeholder="请输入场地id, res_site.id" />
      </a-form-model-item>
      <a-form-model-item label="场地详情快照" prop="siteSnapshot" >
      </a-form-model-item>
      <a-form-model-item label="场次" prop="siteTime" >
      </a-form-model-item>
      <a-form-model-item label="姓名/公司名称" prop="subjectName" >
        <a-input v-model="form.subjectName" placeholder="请输入姓名/公司名称" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="contactPhone" >
        <a-input v-model="form.contactPhone" placeholder="请输入联系电话" />
      </a-form-model-item>
      <a-form-model-item label="预计人数" prop="personNum" >
        <a-input v-model="form.personNum" placeholder="请输入预计人数" />
      </a-form-model-item>
      <a-form-model-item label="活动主题" prop="theme" >
        <a-input v-model="form.theme" placeholder="请输入活动主题" />
      </a-form-model-item>
      <a-form-model-item label="单价" prop="price" >
        <a-input v-model="form.price" placeholder="请输入单价" />
      </a-form-model-item>
      <a-form-model-item label="总天数" prop="totalDay" >
        <a-input v-model="form.totalDay" placeholder="请输入总天数" />
      </a-form-model-item>
      <a-form-model-item label="订单价格 = 单价*总天数" prop="orderAmount" >
        <a-input v-model="form.orderAmount" placeholder="请输入订单价格 = 单价*总天数" />
      </a-form-model-item>
      <a-form-model-item label="应付款 " prop="payAmount" >
        <a-input v-model="form.payAmount" placeholder="请输入应付款 " />
      </a-form-model-item>
      <a-form-model-item label="支付时间" prop="payTime" >
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="三方支付订单号" prop="outTradeNo" >
        <a-input v-model="form.outTradeNo" placeholder="请输入三方支付订单号" />
      </a-form-model-item>
      <a-form-model-item label="是否超时,1:是,0:否" prop="isTimeout" >
        <a-input v-model="form.isTimeout" placeholder="请输入是否超时,1:是,0:否" />
      </a-form-model-item>
      <a-form-model-item label="是否开票,1:是,0:否" prop="isInvoice" >
        <a-input v-model="form.isInvoice" placeholder="请输入是否开票,1:是,0:否" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSiteOrderDetail, addSite, updateSite } from '@/api/order/site'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        siteId: null,

        siteSnapshot: null,

        siteTime: null,

        subjectName: null,

        contactPhone: null,

        personNum: null,

        theme: null,

        price: null,

        orderDay: null,

        totalDay: null,

        orderAmount: null,

        payAmount: null,

        createTime: null,

        payTime: null,

        outTradeNo: null,

        isTimeout: null,

        isInvoice: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        siteId: [
          { required: true, message: '场地id, res_site.id不能为空', trigger: 'blur' }
        ],
        siteSnapshot: [
          { required: true, message: '场地详情快照不能为空', trigger: 'blur' }
        ],
        siteTime: [
          { required: true, message: '场次不能为空', trigger: 'blur' }
        ],
        subjectName: [
          { required: true, message: '姓名/公司名称不能为空', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' }
        ],
        personNum: [
          { required: true, message: '预计人数不能为空', trigger: 'blur' }
        ],
        theme: [
          { required: true, message: '活动主题不能为空', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '单价不能为空', trigger: 'blur' }
        ],
        orderDay: [
          { required: true, message: '已选日期 [\"2023-02-10\",\"2023-02-11\"]不能为空', trigger: 'blur' }
        ],
        totalDay: [
          { required: true, message: '总天数不能为空', trigger: 'blur' }
        ],
        orderAmount: [
          { required: true, message: '订单价格 = 单价*总天数不能为空', trigger: 'blur' }
        ],
        payAmount: [
          { required: true, message: '应付款 不能为空', trigger: 'blur' }
        ],
        isTimeout: [
          { required: true, message: '是否超时,1:是,0:否不能为空', trigger: 'blur' }
        ],
        isInvoice: [
          { required: true, message: '是否开票,1:是,0:否不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        siteId: null,
        siteSnapshot: null,
        siteTime: null,
        subjectName: null,
        contactPhone: null,
        personNum: null,
        theme: null,
        price: null,
        orderDay: null,
        totalDay: null,
        orderAmount: null,
        payAmount: null,
        createTime: null,
        payTime: null,
        outTradeNo: null,
        isTimeout: null,
        isInvoice: null,
        remark: null,
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSiteOrderDetail({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSite(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSite(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
